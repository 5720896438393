<template>
  <div id="merchantDetail">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item>停车场管理</el-breadcrumb-item>
      <el-breadcrumb-item :to="{ name: 'parkingLotList' }"
        >停车场列表</el-breadcrumb-item
      >
      <el-breadcrumb-item>商户详情</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="basicsInfo">
      <h3>基础信息</h3>
      <ul class="info">
        <li>
          <span class="label">商户名称：</span>
          <editable-input
            :value="info.shopName"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_shopName'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="shopName"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">商户位置：</span>
          <editable-input
            :value="info.shopAddress"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_shopAddress'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="shopAddress"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">商户联系人：</span>
          <editable-input
            :value="info.contactName"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_contactName'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="contactName"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">联系人手机号：</span>
          <editable-input
            :value="info.contactPhone"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_contactPhone'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="contactPhone"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">账户余额：</span>
          <editable-input :value="info.remaining_sum" />
        </li>
        <li>
          <span class="label">充值金额：</span>
          <editable-input :value="info.rechargeAmount" />
        </li>
        <li>
          <span class="label">发放金额余额：</span>
          <editable-input
            :value="info.ticketAvailable"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_charge'
              )
            "
            :oncustom="
              () => {
                $refs.ticketAvailableDialog.show = true;
              }
            "
          />
        </li>
        <li>
          <span class="label">按月发放金额：</span>
          <editable-input
            :value="info.ticketPerMonth"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_ticketPerMonth'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="ticketPerMonth"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">大众/美团人均消费：</span>
          <editable-input
            :value="info.averageConsumption"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_averageConsumption'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="averageConsumption"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">是否免租：</span>
          <editable-select
            :value="info.rentFree"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_rentFree'
              )
            "
            :list="rentFree_list"
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="rentFree"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">备注：</span>
          <editable-input
            :value="info.shopRemarks"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_shopRemarks'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="shopRemarks"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">登录名：</span>
          <editable-input
            :value="info.username"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_username'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="username"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">登录密码：</span>
          <editable-input
            value="******"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_password'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="password"
            @save="getInfo"
            :config="{
              type: 'password',
              'show-password': false,
            }"
          />
        </li>
        <li>
          <span class="label">是否多次领取：</span>
          <editable-select
            :value="info.codeMultiUse"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_codeMultiUse'
              )
            "
            :list="rentFree_list"
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="codeMultiUse"
            @save="getInfo"
          />
        </li>
        <li>
          <span class="label">二维码有效期：</span>
          <editable-input
            :value="info.codeExpireMinutes"
            :isRead="
              !buttonList.includes(
                'parkingLotList-detail-merchantTab-detail-info_codeExpireMinutes'
              )
            "
            :params="{ parkingLotId: info.parkingLotId, userId: info.userId }"
            url="/parking/shop/edit"
            attr="codeExpireMinutes"
            @save="getInfo"
          />
        </li>
      </ul>
    </div>
    <div class="tab-table">
      <el-tabs v-model="tabsName">
        <el-tab-pane
          label="优惠券列表"
          name="parkingLotList-detail-merchantTab-detail-coupon_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-coupon_Tab'
            )
          "
        >
          <coupon-list />
        </el-tab-pane>
        <el-tab-pane
          label="发券计划列表"
          name="parkingLotList-detail-merchantTab-detail-couponPlanList_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-couponPlanList_Tab'
            )
          "
        >
          <coupon-plan-list />
        </el-tab-pane>
        <el-tab-pane
          label="已领优惠券列表"
          name="parkingLotList-detail-merchantTab-detail-getCouponList_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-getCouponList_Tab'
            )
          "
        >
          <get-coupon-list />
        </el-tab-pane>
        <el-tab-pane
          label="优惠券订单"
          name="parkingLotList-detail-merchantTab-detail-stopOrder_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-stopOrder_Tab'
            )
          "
        >
          <stop-order />
        </el-tab-pane>
        <el-tab-pane
          label="充值记录"
          name="parkingLotList-detail-merchantTab-detail-rechargeRecord_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-rechargeRecord_Tab'
            )
          "
        >
          <recharge-record />
        </el-tab-pane>
        <el-tab-pane
          label="发券账号"
          name="parkingLotList-detail-merchantTab-detail-sendCoupon_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-sendCoupon_Tab'
            )
          "
        >
          <send-coupon />
        </el-tab-pane>
        <el-tab-pane
          label="余额记录"
          name="parkingLotList-detail-merchantTab-detail-balanceRecord_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-balanceRecord_Tab'
            )
          "
        >
          <balance-record />
        </el-tab-pane>
        <el-tab-pane
          label="车辆列表"
          name="parkingLotList-detail-merchantTab-detail-vehicleList_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-vehicleList_Tab'
            )
          "
        >
          <vehicle-list-tab />
        </el-tab-pane>
        <el-tab-pane
          label="子账号列表"
          name="parkingLotList-detail-merchantTab-detail-subList_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-subList_Tab'
            )
          "
        >
          <sub-list-tab />
        </el-tab-pane>
        <el-tab-pane
          label="挂单车订单列表"
          name="parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab"
          v-if="
            tabList.includes(
              'parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab'
            )
          "
        >
          <free-car-order-list :keyword="params.keyword" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <ticket-available-dialog ref="ticketAvailableDialog" :getInfo="getInfo" />
  </div>
</template>
<script>
export default {
  components: {
    editableInput: () => import("@/components/editable-input.vue"),
    editableSelect: () => import("@/components/editable-select.vue"),
    couponList: () => import("./tabs/couponList/couponList.vue"),
    getCouponList: () => import("./tabs/getCouponList/getCouponList.vue"),
    couponPlanList: () => import("./tabs/couponPlanList/couponPlanList.vue"),
    stopOrder: () => import("./tabs/stopOrder/stopOrder.vue"),
    rechargeRecord: () => import("./tabs/rechargeRecord/rechargeRecord.vue"),
    sendCoupon: () => import("./tabs/sendCoupon/sendCoupon.vue"),
    balanceRecord: () => import("./tabs/balanceRecord/balanceRecord.vue"),
    vehicleListTab: () => import("./tabs/vehicleListTab/vehicleListTab.vue"),
    subListTab: () => import("./tabs/subList/subList.vue"),
    freeCarOrderList: () =>
      import("./tabs/freeCarOrderList/freeCarOrderList.vue"),
    ticketAvailableDialog: () => import("./ticketAvailableDialog.vue"),
  },
  data() {
    return {
      tabsName: "parkingLotList-detail-merchantTab-detail-couponTab",
      params: {},
      info: {},
      rentFree_list: [
        {
          value: "是",
          label: "是",
        },
        {
          value: "否",
          label: "否",
        },
      ],
    };
  },
  created() {
    this.getInfo();
  },
  computed: {
    tabList() {
      let list = this.$store.state.controlAuthority.tabList;
      let tabsName_list = [
        "parkingLotList-detail-merchantTab-detail-coupon_Tab",
        "parkingLotList-detail-merchantTab-detail-couponPlanList_Tab",
        "parkingLotList-detail-merchantTab-detail-getCouponList_Tab",
        "parkingLotList-detail-merchantTab-detail-stopOrder_Tab",
        "parkingLotList-detail-merchantTab-detail-rechargeRecord_Tab",
        "parkingLotList-detail-merchantTab-detail-sendCoupon_Tab",
        "parkingLotList-detail-merchantTab-detail-balanceRecord_Tab",
        "parkingLotList-detail-merchantTab-detail-vehicleList_Tab",
        "parkingLotList-detail-merchantTab-detail-subList_Tab",
        "parkingLotList-detail-merchantTab-detail-freeCarOrderList_Tab",
      ];
      for (let item of tabsName_list) {
        if (list.includes(item)) {
          this.tabsName = item;
          break;
        }
      }
      return list;
    },
  },
  provide() {
    return {
      changeTab: this.changeTab,
    };
  },
  methods: {
    changeTab(tabsName, params) {
      this.tabsName = tabsName;
      Object.assign(this.params, params);
    },
    async getInfo() {
      try {
        let res = await this.$http.get(
          `/parking/shop/${this.$route.params.id}/detail`
        );
        if (res.code === 0) {
          res.data.remaining_sum = `${res.data.accountBalance}（可用余额：${res.data.availableBalance}）`;
          res.data.rechargeAmount = (
            res.data.availableBalance - res.data.ticketAvailable
          ).toFixed(2);
          this.$set(this, "info", res.data);
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<style lang="scss">
#merchantDetail {
  .basicsInfo {
    .info {
      .label {
        width: 115px !important;
      }
    }
  }
  .tab-table {
    padding: 15px;
  }
}
</style>
